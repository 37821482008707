import React, { useState, useEffect, useCallback, Suspense } from 'react';
import Box from '@mui/material/Box';
import { FixMeLater } from './AddTask';
import { styled } from '@mui/material/styles';
import { Mixpanel } from '../environment/mixpanel'
import Icon from '../images/Icon.png'
import { Link } from 'react-router-dom';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const Drawer = React.lazy(() => import('@mui/material/Drawer'));
const AppBar = React.lazy(() => import('@mui/material/AppBar'));
const Toolbar = React.lazy(() => import('@mui/material/Toolbar'));
const List = React.lazy(() => import('@mui/material/List'));
const Typography = React.lazy(() => import('@mui/material/Typography'));
const ListItemButton = React.lazy(() => import('@mui/material/ListItemButton'));
const Grid = React.lazy(() => import('@mui/material/Grid'));


const PREFIX = 'Docs';
const classes = {
  root: `${PREFIX}-root`,
  menuButton: `${PREFIX}-menuButton`,
  hide: `${PREFIX}-hide`,
  
  appBar: `${PREFIX}-appBar`,
  appBarShift: `${PREFIX}-appBarShift`,
  content: `${PREFIX}-content`,
  contentShift: `${PREFIX}-contentShift`,
  drawer: `${PREFIX}-drawer`,
  drawerHeader: `${PREFIX}-drawerHeader`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  
}

const drawerWidth = 240;

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: 1,
  },
  [`&.${classes.menuButton}`]: {
    marginRight: theme.spacing(2),
  },
  [`&.${classes.hide}`]: {
    display: 'none',
  },
  [`&.${classes.appBar}`]: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  [`&.${classes.appBarShift}`]: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  [`&.${classes.drawer}`]: {
    width: drawerWidth,
    flexShrink: 0,
  },
  [`&.${classes.drawerHeader}`]: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    [`&.${classes.drawerPaper}`]: {
    width: drawerWidth,
  },
  [`&.${classes.content}`]: {
    flexGrow: 1,
    padding: theme.spacing(1),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  [`&.${classes.contentShift}`]: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
  },


}), { index: 1 });


const Docs = (props:{id:number} ) => {
  const initialpost = {content:{rendered:'<div/>'}}
  const [post,setPost] = useState(initialpost)
  const [posts, setPosts] = useState<FixMeLater>([]);
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenuClick = useCallback((clickpost:FixMeLater) => {
    Mixpanel.track('Docs:Menuclick');
    setPost(clickpost)
    setMobileOpen(false)
  },[]);

    useEffect(() => {
        async function loadPosts() {
            const response = await fetch('https://wordpress.wrgroceries.com//wp-json/wp/v2/posts');
            if(!response.ok) {
                // oups! something went wrong
                return;
            }
    
            const posts:FixMeLater = await response.json();
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            posts.sort((a:FixMeLater,b:FixMeLater) => a.id - b.id)
            setPosts(posts);
            if(posts.length > 0) {
                if(props.id !== 0) {
                    const p = posts.find((p:FixMeLater) => p.id === props.id)
                    setPost(p)
                } else {
                    setPost(posts[0])
                }
            }
        }
    
        loadPosts();
   }, [props.id])

  const drawer = (<div>
              <Toolbar />
          <Box sx={{ overflow: 'auto' }}>
          <List>
            {posts.map((post:FixMeLater, index:number) => (
              <ListItemButton key={index} onClick={() => { handleMenuClick(post) }}>
                <Grid container alignItems="center">
                  <Grid item xs={8}>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    dangerouslySetInnerHTML={{__html: post.title.rendered}} />
                  </Grid>
                </Grid>
              </ListItemButton>
            ))}
          </List>
          </Box>

  </div>)   

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Root className={classes.root}>
      <Box sx={{ display: 'flex',width:'100%' }} id="appbarcontainer">
        <AppBar position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }} >
          <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
            <Link to="/">
                <img src={Icon} alt="Home" style={{float:'left',width:16, height:16}}/>
            </Link>
            
            <Typography variant="h6" sx={{ flexGrow: 1,p:2 }}>
              Docs
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}>
                {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
        </Box>
        <Box component="main" sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }} id="maincontent">
          <Toolbar />
          <Typography
                    variant="body2"
                    dangerouslySetInnerHTML={{__html: post.content.rendered}} />
        </Box>
        </Box>
      </Root>
    </Suspense>
  );
}

export default Docs;