import React, { useState, useEffect, useCallback, Suspense } from 'react';
import { useNavigate } from "react-router-dom";
import { API_URL, PROT } from '../environment/current';
import Box from '@mui/material/Box';
import { FixMeLater } from './AddTask';
import { styled } from '@mui/material/styles';
import { Mixpanel } from '../environment/mixpanel'

const Drawer = React.lazy(() => import('@mui/material/Drawer'));
const AppBar = React.lazy(() => import('@mui/material/AppBar'));
const Toolbar = React.lazy(() => import('@mui/material/Toolbar'));
const List = React.lazy(() => import('@mui/material/List'));
const Typography = React.lazy(() => import('@mui/material/Typography'));
const IconButton = React.lazy(() => import('@mui/material/IconButton'));
const MenuIcon = React.lazy(() => import('@mui/icons-material/Menu'));
const KeyboardBackspaceIcon = React.lazy(() => import('@mui/icons-material/KeyboardBackspace'));
const Add = React.lazy(() => import('@mui/icons-material/Add'));
const DeleteIcon = React.lazy(() => import('@mui/icons-material/Delete'));
const ListItemButton = React.lazy(() => import('@mui/material/ListItemButton'));
const ListItemText = React.lazy(() => import('@mui/material/ListItemText'));
const Board = React.lazy(() => import('./Board'));
const StoreBoard = React.lazy(() => import('./StoreBoard'));
const ShelfBoard = React.lazy(() => import('./ShelfBoard'));
const AgileBoard = React.lazy(() => import('./AgileBoard'));
const Account = React.lazy(() => import('./Account'));
const Dialog = React.lazy(() => import('@mui/material/Dialog'));
const DialogActions = React.lazy(() => import('@mui/material/DialogActions'));
const DialogContent = React.lazy(() => import('@mui/material/DialogContent'));
const DialogTitle = React.lazy(() => import('@mui/material/DialogTitle'));
const Button = React.lazy(() => import('@mui/material/Button'));
const TextField = React.lazy(() => import('@mui/material/TextField'));
const Grid = React.lazy(() => import('@mui/material/Grid'));
const CheckCircle = React.lazy(() => import('@mui/icons-material/CheckCircle'));
const Money = React.lazy(() => import('@mui/icons-material/Money'));
const HorizontalSplitIcon = React.lazy(() => import('@mui/icons-material/HorizontalSplit'));
const Fab = React.lazy(() => import('@mui/material/Fab'));

const PREFIX = 'MainMenu';
const classes = {
  root: `${PREFIX}-root`,
  menuButton: `${PREFIX}-menuButton`,
  hide: `${PREFIX}-hide`,
  
  appBar: `${PREFIX}-appBar`,
  appBarShift: `${PREFIX}-appBarShift`,
  content: `${PREFIX}-content`,
  contentShift: `${PREFIX}-contentShift`,
  drawer: `${PREFIX}-drawer`,
  drawerHeader: `${PREFIX}-drawerHeader`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  
}

const drawerWidth = 240;

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: 1,
  },
  [`&.${classes.menuButton}`]: {
    marginRight: theme.spacing(2),
  },
  [`&.${classes.hide}`]: {
    display: 'none',
  },
  [`&.${classes.appBar}`]: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  [`&.${classes.appBarShift}`]: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  [`&.${classes.drawer}`]: {
    width: drawerWidth,
    flexShrink: 0,
  },
  [`&.${classes.drawerHeader}`]: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    [`&.${classes.drawerPaper}`]: {
    width: drawerWidth,
  },
  [`&.${classes.content}`]: {
    flexGrow: 1,
    padding: theme.spacing(1),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  [`&.${classes.contentShift}`]: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
  },


}), { index: 1 });



function flat(input: FixMeLater, depth = 1, stack: FixMeLater = []) {
  for (let item of input) {
    if (item instanceof Array && depth > 0) {
      flat(item, depth - 1, stack);
    }
    else {
      stack.push(item);
    }
  }

  return stack;
}

const BoardWrapper = (props: { menulink: FixMeLater, board: FixMeLater, custommenu: string, postShopping: boolean }) => {
  if (props === null || props.board === null || typeof (props.board.boardtype) == 'undefined' || props.board.valuelink === '') {
    return (
      <Typography>Pick a Board</Typography>
    )
  } else if (props.custommenu === 'Account') {
    return (<Account />)
  }

  if (props.board.boardtype === 'GROCERIES') {
    const token = localStorage.getItem("jwt");
    if (props.board.mode === 2 || props.postShopping ===true) {
      return (
        <StoreBoard link={props.board.link} boards={props.menulink} jwt={token} postShopping={props.postShopping}/>
      )
    } else if(props.board.mode === 3) {
      return (
        <ShelfBoard link={props.board.link} boards={props.menulink} jwt={token} postShopping={props.postShopping}/>
      )
    } else {
      return (
        <Board link={props.board.link} boards={props.menulink} jwt={token}/>
      )
    }
  } else {
    return (
      <AgileBoard link={props.board.link} boards={props.menulink}  />
    )
  }
}

const MainMenu = (props: {postShopping:boolean}) => {
  const [omenu, setOmenu] = useState(false);
  const [menulink, setMenuLink] = useState([]);
  const [custommenu, setCustommenu] = useState('')
  const [currentboard, setCurrentboard] = useState<FixMeLater | ''>('');
  const [open, setOpen] = React.useState(false);
  const [listname, setListname] = useState('');
  const [cacheparam, setCached] = useState(0);
  let navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOmenu(true);
  };

  const handleAddList = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOmenu(false);
  };

  const handleMenuClick = useCallback((newboard:FixMeLater) => {
    Mixpanel.track('MainMenu:Menuclick');
    setCustommenu('')
    setCurrentboard(newboard);
    const vuser = JSON.parse(localStorage.getItem("userlinks")).href;
    const bid = newboard.link.split("/");
    localStorage.setItem("lastboard", newboard.link)
    const turl2 = vuser + "/update_lastboard/" + bid[bid.length - 1];
    const token = localStorage.getItem("jwt");
    fetch(turl2, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        'Authorization': token
      },
      body: JSON.stringify(newboard),
    })
      .catch((err) => {
        setListname('');
        console.error(err);
      });

    handleDrawerClose();
  },[]);

  const handleMenuDelete = (link: string) => {
    Mixpanel.track('MainMenu:DeleteList');
    const token = localStorage.getItem("jwt");
    fetch(link, {
      method: "DELETE",
      headers: {
        'Authorization': token
      },
    })
      .then(() => {
        setCached(cacheparam + 1);
      })
      .catch((err) => {
        setCached(cacheparam + 1);
        console.error(err);
      });

    handleDrawerClose();
  };

  const logout = () => {
    localStorage.clear();
    navigate('/login');
  }

  const account = () => {
    Mixpanel.track('MainMenu:Account');
    setCustommenu('Account')
    handleDrawerClose();
  }

  const handleClose = () => {
    setOpen(false);
    setListname('');


  };

  const addList = () => {
    Mixpanel.track('MainMenu:Addlist');
    const token = localStorage.getItem("jwt");
    const newboard = { 'name': listname, 'type': 5, 'mode': 1 }
    fetch(API_URL + "boards", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': token
      },
      body: JSON.stringify(newboard),
    })
      .then((response) => response.json())
      .then(newboard => {
        const userlinks = JSON.parse(localStorage.getItem("userlinks"));
        const team = userlinks.firstteam.split("/");
        const turl2 = newboard._links.self.href.replace('http:', PROT) + "/update_team/" + team[team.length - 1];
        fetch(turl2, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            'Authorization': token
          },
          body: JSON.stringify(newboard),
        })
          .then((ret) => {
            setCached(cacheparam + 1);
            setListname('');
          })
          .catch((err) => {
            setListname('');
            console.error(err);
          });
      })
      .catch((err) => {
        console.error(err);
      });
    handleClose()
  };

  const handleChange = (event: FixMeLater) => {
    setListname(event.target.value)
  }

  useEffect(() => {
    Mixpanel.track('MainMenu');
    const myAbortController = new AbortController();
    const token = localStorage.getItem("jwt");
    const userlinks = JSON.parse(localStorage.getItem("userlinks"));
    fetch(userlinks.teamlink, {
      headers: { 'Authorization': token },
      signal: myAbortController.signal
    })
      .then((response) => response.json())
      .then((responseData) => {
        Promise.all(responseData._embedded.teams.map((team: FixMeLater, index: number) =>
          fetch(team._links.boards.href.replace('http:', PROT), {
            headers: { 'Authorization': token }
          })
            .then((res2) => res2.json())
            .then(responseData2 => responseData2._embedded.boards.map(
              (actboard: FixMeLater) => ({
                teamname: responseData._embedded.teams[index].name,
                boardname: actboard.name,
                boardtype: actboard.btype,
                totalsavings: actboard.totalsavings,
                mode: actboard.mode,
                link: actboard._links.self.href.replace('http:', PROT)
              }))
            )))
          .then(
            data => {
              var dataf: FixMeLater = [];
              flat(data, 1, dataf);
              setMenuLink(dataf);
              const lastboard = localStorage.getItem("lastboard")
              const lastdata = dataf.filter((d: { link: string; }) => d.link === lastboard)
              if (lastdata.length > 0) {
                handleMenuClick(lastdata[0])
              }
              else if (dataf.length > 0) {
                handleMenuClick(dataf[0])
              }
            }
          )
      })
      .catch((err) => console.error(err));

    return () => {
      myAbortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cacheparam]);

  const handleStoresList = () => {
    setmode(2)
    navigate('/main')
  }

  const setmode = (mode: number) => {
    const newboard = { 'mode': mode }
    const token = localStorage.getItem("jwt");
    const turl2 = currentboard.link.replace('http:', PROT);
    const fupdate = {...currentboard,'mode':mode}
    setCurrentboard(fupdate)
    fetch(turl2, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        'Authorization': token
      },
      body: JSON.stringify(newboard),
    })
      .then((ret) => {
        setCached(cacheparam + 1);
      })
      .catch((err) => {
        console.error(err);
      });

  }

  const handleTasksList = () => {
    setmode(1)
    navigate('/main')
  }

  const handleShelfsList = () => {
    setmode(3)
    navigate('/main')
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Root className={classes.root}>
      <Box sx={{ display: 'flex',width:'100%',backgroundColor:"#FFFFFF" }} id="appbarcontainer">
        <AppBar sx={{backgroundColor:"#FFFFFF"}} elevation={0}>
          <Toolbar sx={{mt:3,backgroundColor:"#FFFFFF",color:"#292929"}}>
            <IconButton
              size="large"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              id="menubutton"
              sx={{ mr: 0 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              WrGroceries
            </Typography>
            <IconButton sx={{ flexGrow: 0.02 }}
              color={currentboard === null || currentboard==='' || currentboard.mode === 1 ? "primary":"info"}
              aria-label="open drawer"
              onClick={handleTasksList}
              edge="start"
              id="menubutton3"

            >
              <CheckCircle />
            </IconButton>
            <IconButton sx={{ flexGrow: 0.1 }}
              color={currentboard !== null && currentboard!=='' && currentboard.mode === 3 ? "primary":"info"}
              aria-label="open drawer"
              onClick={handleShelfsList}
              edge="start"
              id="menubutton4"

            >
              <HorizontalSplitIcon />
            </IconButton>
            <IconButton
              color={currentboard !== null && currentboard!=='' && currentboard.mode === 2 ? "primary":"info"}
              aria-label="open drawer"
              onClick={handleStoresList}
              edge="start"
              id="menubutton2"

            >
              <Money />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
        open={omenu}>
          <Grid container alignItems="center">
            <Grid item xs={12} mt={4} ml={2}>
              <Fab color="primary" size="small" sx={{color:"#FFFFFF"}} aria-label="add" onClick={handleAddList}>
                <Add />
              </Fab>
              </Grid>
            <Grid item xs={8} mt={2} ml={2}>
              <Typography sx={{color:"#B7B7B7"}}>
                Lists
              </Typography>
            </Grid>
            <Grid item xs={2} mt={2}>
              <IconButton onClick={handleDrawerClose}>
                <KeyboardBackspaceIcon />
              </IconButton>
            </Grid>
          </Grid>
          <List>
            {menulink.map((element, index) => (
              <ListItemButton key={index} onClick={() => { handleMenuClick(element) }}>
                <Grid container >
                  <Grid item xs={10}>
                    <ListItemText primary={element.boardname} id={element.teamname + "_" + element.boardname} />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton onClick={() => { handleMenuDelete(element.link) }}>
                      <DeleteIcon color="secondary"/>
                    </IconButton>
                  </Grid>
                </Grid>


              </ListItemButton>
            ))}
            <ListItemButton key="Account" onClick={() => { account() }}>
              <ListItemText primary="Account" />
            </ListItemButton>
            <ListItemButton key="Logout" onClick={() => { logout() }}>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </List>
        </Drawer>
        <Box component="main" sx={{ mt: 8, flexGrow: 20, width: '100%',backgroundColor: '#FAFAFA' }} id="maincontent">
          <Toolbar />
          <Dialog
            open={open}
            onClose={handleClose}
          >
            <DialogTitle id="alert-dialog-title">{"Add List"}</DialogTitle>
            <DialogContent>
              <TextField autoFocus label="List Name" name="listname" value={listname} onChange={handleChange} />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" autoFocus>
                Cancel
              </Button>
              <Button onClick={addList} color="primary" >
                Add
              </Button>
            </DialogActions>
          </Dialog>
          <BoardWrapper menulink={menulink} board={currentboard} custommenu={custommenu} postShopping={props.postShopping} />
        </Box>
        </Box>
      </Root>
    </Suspense>
  );
}

export default MainMenu;