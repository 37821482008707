import './App.css';
import RoutePage from './components/RoutePage';
import { CssBaseline } from "@mui/material";
import { createMuiTheme,ThemeProvider } from '@mui/material/styles';
import { store } from './redux/store'
import { Provider } from 'react-redux'
const thetheme = () => createMuiTheme({
  typography: {
    fontFamily: 'GilroySemibold',
  },
    palette: {
      background: {
        default: '#FAFAFA'
      },
      primary: {
        // light: will be calculated from palette.primary.main,
        main: '#4ABF78',
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        main: "#FF3F5B"
      },
      info: {
        main: "#B7B7B7"
      }
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'GilroySemibold';
            font-weight: 600;
            src: url(./fonts/Gilroy-SemiBold) format('ttf');
          }
        `,
      }},
  });

function App () {
    return (
        <div>
          <Provider store={store}>
          <ThemeProvider theme={thetheme()}>
                <CssBaseline />
                <RoutePage/>
            </ThemeProvider>
          </Provider>
        </div>            
    );
}

export default App;