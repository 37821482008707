import {
  useNavigate,
} from 'react-router-dom'
import { FixMeLater } from './AddTask';

function PrivateRoute( {children, ...rest}:FixMeLater ) {
    let navigate = useNavigate()
    const jwt = localStorage.getItem("jwt");
    return  (jwt != null && jwt !== "") ? children : navigate('/login')
  }

export default PrivateRoute;
