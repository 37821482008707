import mixpanel from 'mixpanel-browser';
import {ENV_NAME} from './current'
mixpanel.init('0deb6ac2d9ee7f8b04661a1ef26490bb', {debug: true, api_host: "https://api-eu.mixpanel.com",ignore_dnt: true});
let env_check = (ENV_NAME === 'prod' || ENV_NAME === 'staging');
var userAgentBotTest = navigator.userAgent; 
var urlf=document.referrer.toString()
mixpanel.register({"User Agent": userAgentBotTest}); 
if (/(Mozilla\/4.0)/i.test(userAgentBotTest) || urlf.includes("keywordblocks.com")>0 || urlf.includes("becovi.com")>0) { 
    mixpanel.register({"$ignore": true}); 
}

let actions = {
  identify: (id) => {
    if (env_check) {
        if(ENV_NAME ==='staging') {
            mixpanel.identify('test');
        } else {
            mixpanel.identify(id);
        }
    }
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (env_check) mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;