export const API_URL='https://wrgroceries.com/api/';
export const TOKEN_URL='https://wrgroceries.com/oauth/token';
export const AUTHORIZATION_URL='https://wrgroceries.com/oauth/authorize';
export const REDIRECT_URL='http://wrgroceries.com/oauth/callback';
export const USER_URL='https://wrgroceries.com/user/add';
export const USERS_URL='https://wrgroceries.com/users/';
export const BASE_URL='https://wrgroceries.com';
export const MATOMO_ID=1;
export const ENV_NAME='prod';
export const PROT='https:';
