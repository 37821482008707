import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';
import { FixMeLater } from '../components/AddTask';
import { fetchCount } from './taskAPI';

export interface Task {
  amount: number;
  boardId: number;
  classificationId: number;
  cstring: string;
  id: number;
  importance: number;
  storeprices: FixMeLater[];
  sunit: string;
  title: string;
  unit: number;
}

export interface TasksState {
  tasks: Task[];
  status: 'idle' | 'loading' | 'failed';
  error: string | null
}

const initialState: TasksState = {
  tasks: [],
  status: 'idle',
  error: null
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const incrementAsync = createAsyncThunk(
  'counter/fetchCount',
  async (amount: number) => {
    const response = await fetchCount(amount);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const fetchTasks = createAsyncThunk('tasks/id/task_with_status/TODO', 
  async (link:string) =>{
  const URL = link + '/task_with_status/TODO';
  const token = localStorage.getItem("jwt");
  const response = await fetch(URL, {
      method: "GET",
      headers: {
          "Content-Type": "application/json",
          'Authorization': token
      },
      })
  const responseData = await response.json()
  if (responseData == null || responseData.content == null || responseData.content.length <= 0) {
        return []
    } else {
      return responseData.content;
    }
})

export const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    addTask: (state,action) => {
      state.tasks = [...state.tasks,action.payload];
    },
    updateTask: (state,action) => {
      const { amount, boardId, classificationId, id , importance, storeprices, sunit, title, unit,cstring } = action.payload
      const task = state.tasks.find(obj=>obj.title === action.payload.title)
      if(task) {
        task.amount=amount
        task.boardId=boardId
        task.classificationId=classificationId
        task.id=id
        task.importance=importance
        task.storeprices=storeprices
        task.sunit=sunit
        task.title=title
        task.unit=unit
        task.cstring = cstring
      }
      else {
        state.tasks = [...state.tasks,action.payload];
      }
    },
    updatePrice: (state,action) => {
      const { id, storeid, price } = action.payload
      const task = state.tasks.find(obj=>obj.id === id)
      if(task) {
        const siid = parseInt(storeid)
        task.storeprices[siid]=price
      }
    },
    deleteTask: (state,action) => {
      state.tasks = state.tasks.filter( el => el.title !== action.payload.title )
    },
    setTasks: (state,tasks:FixMeLater) => {
      state.tasks = tasks;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchTasks.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTasks.fulfilled, (state, action) => {
        state.status = 'idle';
        state.tasks = action.payload;
      })
      .addCase(fetchTasks.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { addTask,setTasks,updateTask, deleteTask,updatePrice } = tasksSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectTasks = (state: RootState) => state.tasks.tasks;

export default tasksSlice.reducer;
