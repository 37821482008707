import React from 'react'
// @ts-ignore
import {
    BrowserRouter,
    Route,
    Routes
} from 'react-router-dom'
import Docs from './Docs';

import LandingPage from './Landingpage';
import MainMenu from './MainMenu';
import PrivateRoute from "./Privateroute";
const Fabtutorial = React.lazy(() => import('../blog/fabtutorial'));
const Jenkinstutorial = React.lazy(() => import('../blog/monorepo/jenkinstutorial'));
const Login = React.lazy(() => import('./Login'));
const PrivacyPage = React.lazy(() => import('./privacy'));
const Signup = React.lazy(() => import('./Signup'));
const Throwaway = React.lazy(() => import('./Throwaway'));

/*

*/

function RoutePage() {
    return (
        <BrowserRouter>
            <Routes>
                <Route index element={
                    <React.Suspense fallback={<span>Loading...</span>}>
                        <LandingPage />
                    </React.Suspense>
                } />
                <Route path="postshopping/*" element={
                    <PrivateRoute >
                        <React.Suspense fallback={<span>Loading...</span>}>
                            <MainMenu postShopping={true} />
                        </React.Suspense>
                    </PrivateRoute> }
                />
                <Route path="main/*"  element={
                    <PrivateRoute >
                        <React.Suspense fallback={<span>Loading...</span>}>
                            <MainMenu postShopping={false} />
                        </React.Suspense>
                    </PrivateRoute> 
                }/>
                <Route path="throwaway/*" element={
                    <React.Suspense fallback={<span>Loading...</span>}>
                        <Throwaway />
                    </React.Suspense>
                } />
                <Route path="privacypolicy/*" element={
                    <React.Suspense fallback={<span>Loading...</span>}>
                        <PrivacyPage />
                    </React.Suspense>
                } />
                <Route path="getstarted/*" element={
                    <React.Suspense fallback={<span>Loading...</span>}>
                        <Signup />
                    </React.Suspense>
                } />
                <Route path="login/*" element={
                    <React.Suspense fallback={<span>Loading...</span>}>
                        <Login />
                    </React.Suspense>
                } />
                <Route path="fabtutorial/*" element={
                    <React.Suspense fallback={<span>Loading...</span>}>
                        <Fabtutorial />
                    </React.Suspense>
                } />
                <Route path="jenkinstutorial/*" element={
                    <React.Suspense fallback={<span>Loading...</span>}>
                        <Jenkinstutorial />
                    </React.Suspense>
                } />
                <Route path="docs/">
                  <Route path="12"  element={
                    <React.Suspense fallback={<span>Loading...</span>}>
                        <Docs id={12} />
                    </React.Suspense> }/>
                  <Route path=""  element={
                  <React.Suspense fallback={<span>Loading...</span>}>
                      <Docs id={0} />
                  </React.Suspense>        
                  } />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default RoutePage;