import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';
import { FixMeLater } from '../components/AddTask';

interface DoneTask {
  amount: number;
  boardId: number;
  classificationId: number;
  cstring: string;
  id: number;
  importance: number;
  storeprices: FixMeLater[];
  sunit: string;
  title: string;
  unit: number;
}

export interface TasksDoneState {
  donetasks: DoneTask[];
  status: 'idle' | 'loading' | 'failed';
  error: string | null
}

const initialState: TasksDoneState = {
  donetasks: [],
  status: 'idle',
  error: null
};

export const fetchDoneTasks = createAsyncThunk('tasks/id/task_with_status/DONE', 
  async (link:string) =>{
  const URL = link + '/task_with_status/DONE';
  const token = localStorage.getItem("jwt");
  const response = await fetch(URL, {
      method: "GET",
      headers: {
          "Content-Type": "application/json",
          'Authorization': token
      },
      })
  const responseData = await response.json()
  if (responseData == null || responseData.content == null || responseData.content.length <= 0) {
        return []
    } else {
      return responseData.content;
    }
})

export const tasksDoneSlice = createSlice({
  name: 'donetasks',
  initialState,
  reducers: {
    addDoneTask: (state,action) => {
      state.donetasks = [...state.donetasks,action.payload];
    },
    deleteDoneTask: (state,action) => {
      state.donetasks = state.donetasks.filter( el => el.title !== action.payload.title )
    },
    updateDoneTask: (state,action) => {
      const { amount, boardId, classificationId, id , importance, storeprices, sunit, title, unit,cstring } = action.payload
      const task = state.donetasks.find(obj=>obj.title === action.payload.title)
      if(task) {
        task.amount=amount
        task.boardId=boardId
        task.classificationId=classificationId
        task.id=id
        task.importance=importance
        task.storeprices=storeprices
        task.sunit=sunit
        task.title=title
        task.unit=unit
        task.cstring = cstring
      }
    },
    updateDonePrice: (state,action) => {
      const { id, storeid, price } = action.payload
      const task = state.donetasks.find(obj=>obj.id === id)
      if(task) {
        const siid = parseInt(storeid)
        task.storeprices[siid]=price
      }
    },
    clearDoneTasks: (state) => {
      state.donetasks = []
    },
    setDoneTasks: (state,tasks:FixMeLater) => {
      state.donetasks = tasks;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchDoneTasks.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDoneTasks.fulfilled, (state, action) => {
        state.status = 'idle';
        state.donetasks = action.payload;
      })
      .addCase(fetchDoneTasks.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { addDoneTask,setDoneTasks,updateDoneTask,deleteDoneTask,clearDoneTasks,updateDonePrice } = tasksDoneSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectDoneTasks = (state: RootState) => state.donetasks.donetasks;

export default tasksDoneSlice.reducer;
