import { useEffect,useState } from 'react';
import {Link} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Button} from  '@mui/material';
import { Helmet } from "react-helmet";
import { Mixpanel } from '../environment/mixpanel'
//import Platform from 'platform'
import Main2 from '../images/Main2.png'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import FolderIcon from '@mui/icons-material/Folder';

const PREFIX = 'LandingPage';
const classes= {
    root: `${PREFIX}-root`,
    textField: `${PREFIX}-textField`,
    toolbarMargin: `${PREFIX}-toolbarMargin`,
}

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
      display: 'flex',
      flexWrap: 'wrap',
      flexGrow: 1,
      background: '#FAFAFA'
    },
    [`&.${classes.textField}`]: {
        width: '25ch',
    },  
    [`&.${classes.toolbarMargin}`]: theme.mixins.toolbar,
  }));

  const MyComponent = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    width: '250px'
  });

const Storebadges = () =>  {
    /*
    const [googlep,setGooglep] = useState(false);
    

    useEffect(() => {
        if(!Platform.os.toString().includes('iOS') && !Platform.os.toString().includes('Mac OS')) {
            setGooglep(true);
        }
    }, [])    
    if(googlep) {
        return (
        <div className="row">
            <a href='https://play.google.com/store/apps/details?id=com.doneviz&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' width="200"/>
            </a>
        </div>
        )
    } else {*/
        return (<div></div>)
    //}
}

const DesktopImg = (props: {ismobile:boolean}) =>{
    if(!props.ismobile) {
        return (
            <Grid item xs={6} >
                <MyComponent>
                    <img src={Main2} alt="screenshot" style={{width:237}}/>
                </MyComponent>
        </Grid>
        )
    } else {
        return (<div/>)
    }
}


const MobileImg = (props: {ismobile:boolean}) =>{
    if(props.ismobile) {
        return (
            <Grid item xs={12} mt={2}>
                <Grid container justifyContent="center">
                    <img
                        src={Main2}
                        srcSet={Main2}
                        alt="screenshot"
                        height="300vh"
                        loading="lazy"
                    />
                </Grid>
            </Grid>
        )
    } else {
        return (<div/>)
    }
}


function LandingPage() {
    const [isMobile, setIsMobile] = useState(false)
    const {t} =  useTranslation();

 
    //choose the screen size 
    const handleResize = () => {
      if (window.innerWidth < 720) {
          setIsMobile(true)
      } else {
          setIsMobile(false)
      }
    }

    useEffect(() => {
        Mixpanel.track('Landing Page');
        if (window.innerWidth < 720) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }  
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [])    

    /* 
    */
    return (
        <Root style={{ color: '#292929', backgroundColor:'#FAFAFA'}}>
            <Helmet>
                <title>Wearable groceries, a groceries list and TODO list application.</title>
                <meta name="description" content="Wearable groceries, a groceries list and TODO list application. We have created it to be fast, easy to use and without a hassle. Remember what you should do and in what order you want to do it. The webversion is also completely free to use, enjoy!" />
            </Helmet>
            <Container maxWidth='lg'>
            <Grid container spacing={0} direction="row" alignItems="center" mt={0} ml={0} style={{ color: '#292929', backgroundColor:'#FFFFFF'}}>
            <Grid container spacing={0} direction="row" alignItems="center" mt={4} ml={{xs:2,lg:8}} mb={2} style={{ color: '#292929', backgroundColor:'#FFFFFF'}}>
                <FolderIcon/>
                    <Typography mt={0.3} left={0} ml={{xs:1,lg:0.7}} p={0}>
                    <Link to="/docs" style={{ color: '#000' }}>Docs</Link>
                    </Typography>
            </Grid>
            </Grid>
            <Grid container spacing={0} style={{ color: '#292929', backgroundColor:'#FAFAFA'}} mt={{xs:0,lg:5}} ml={{xs:0,lg:14}}>
                <Grid item xs={12} sm={6}>
                    <Grid container spacing={0} style={{ color: '#292929', backgroundColor:'#FAFAFA'}} mt={{xs:1,lg:4}}>
                        <Grid item xs={12}>
                            <Typography variant={isMobile ? "h3":"h1"} style={{fontSize:'2'}} align={isMobile ? 'center' : 'inherit'}>
                            WrGroceries
                            </Typography>
                        </Grid>
                        <Grid item xs={12} mt={{xs:2,lg:4}}>
                            <h5 className="bold-four">
                                <Typography variant={isMobile ? "body1":"h6"} align={isMobile ? 'center' : 'inherit'}>
                                    {t('LANDINPAGECONTENT')}
                                </Typography>
                                
                            </h5>
                            <h5 className="bold-four">
                            <Typography variant={isMobile ? "body1":"h6"} align={isMobile ? 'center' : 'inherit'}>
                                {t('LANDINPAGECONTENT2')}
                                </Typography>
                            </h5>
                        </Grid>
                        <Grid item xs={12} mt={4}>
                            <Stack direction="row" spacing={2} mb={2} mt={{xs:0,lg:2}} justifyContent={isMobile ? "center":"flex-start"}>
                            <Link id="getstarted" to="/getstarted">
                                <Button variant="outlined" color="primary" size="large" sx={{width:140, color: '#FFFFFF', backgroundColor: '#4ABF78', borderColor: '#4ABF78' }}>
                                    {t('Get Started')}
                                </Button>
                            </Link> 
                            <Link to="/login">
                                <Button variant="outlined" color="primary" id="login" size="large" sx={{width:140}}>
                                    {t('Login')}
                                </Button>
                            </Link>

                            </Stack>
                        </Grid>
                        <MobileImg ismobile={isMobile}/>
                        <Grid item xs={12} mt={4}>
                            <Typography align={isMobile ? 'center' : 'inherit'}>
                                Not sure?
                            </Typography>
                            <Storebadges/>
                        </Grid>
                        <Grid item xs={12} mt={1}>
                            <Typography align={isMobile ? 'center' : 'inherit'}>
                                Try a <Link id="getstarted" to="/throwaway">throwaway</Link> list without even logging in.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <DesktopImg ismobile={isMobile}/>
            </Grid>
            </Container>
        </Root>
    );
}

export default LandingPage;